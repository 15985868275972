import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import '../../styles/module-certificate.scss';
import {SvgLoader, SvgProxy} from 'react-svgmt';
import {getParameterByName} from '../../Templates/Shared/Utils';
import * as apiService from '../../api';
import Header from '../../Templates/Shared/Header';
import Footer from '../../Templates/Shared/Footer';
import englishCoursesJson from '../../../data/englishCourses.json';
import nonEnglishCoursesJson from '../../../data/nonEnglishCourses.json';

/**
 *
 *
 * @return {*}
 */
function PublicModuleCertificate() {
  const allCoursesList = [...englishCoursesJson.edges,
    ...nonEnglishCoursesJson.edges];
  const locale = 'en';
  const svgRef = useRef(null);
  const [showError, setError] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [moduleData, setModuleData] = useState(null);
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  today = mm + '/' + dd + '/' + yyyy;

  const getCredentials = () => {
    const formId = getParameterByName('form_id');
    const responseId = getParameterByName('response_id');
    return {
      formId,
      responseId,
    };
  };

  const fetchCertificateData = () => {
    const {formId, responseId} = getCredentials();
    new apiService.default.Certificate()
        .getCertificateData(formId, responseId).then((res) => {
          const leadData = ({...res.data, date: today});
          if (!leadData['user_name']) {
            throw new Error;
          }
          setLeadData(leadData);
          const course = allCoursesList.filter(
              (course) =>
            course?.node?.courseslug === leadData?.course_slug)?.[0];
          setModuleData(course?.node?.
              modules[parseInt(leadData?.module_number) - 1]);
        }).catch((err) => {
          console.log(err);
          setError(true);
        });
  };

  useEffect(() => {
    fetchCertificateData();
  }, []);

  return (
    <div className="sh-module-certificate">
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap" rel="stylesheet" />
      </Helmet>
      <Header locale={locale} />
      <main>
        {!showError ? <div className='main-wrapper'>
          <div className='main-content-wrapper certContainer'>
            <div ref={svgRef} className='svg-wrapper'>
              <SvgLoader
                path={`/images/module-certificate/certificate-${locale}.svg`}>
                <SvgProxy
                  selector="#resultPath">
                  {leadData['grade']}
                </SvgProxy>
                <SvgProxy
                  selector="#datePath">
                  {leadData['date']}
                </SvgProxy>
                <SvgProxy
                  selector="#namePath">
                  {leadData['user_name']}
                </SvgProxy>
                <SvgProxy
                  selector="#moduleNamePath">
                  {
                    moduleData?.certificatedisplayname ?
                      moduleData?.certificatedisplayname :
                      leadData['module_name']
                  }
                </SvgProxy>
              </SvgLoader>
            </div>
          </div>
        </div> :
          <div className='error-wrapper'>
            Something went wrong. Please Try again later...
          </div>}
      </main>
      <Footer />
    </div>
  );
}

PublicModuleCertificate.defaultProps = {
  data: {},
};

export default PublicModuleCertificate;
